<script>
import { required, sameAs } from 'vuelidate/lib/validators';

import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers';

export default {
    data() {
        return {
            password: '',
            password_confirm: '',
            token: this.$route.query.token,
            submitted: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        password: {
            required,
            validate() {
                if (this.password && this.password.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        },
        password_confirm: {
            required,
            sameAsPassword: sameAs('password'),
            validate() {
                if (this.password_confirm && this.password_confirm.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password_confirm)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        }
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (this.password && this.password_confirm) {
                    const obj = {
                        password: this.password,
                        password_confirm: this.password_confirm,
                        token: this.token
                    };
                    this.resetPass(obj).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/login/' }).then(() => {
                                this.$bvToast.toast(`success`, {
                                    title: `パスワードが更新されました。`,
                                    variant: 'success',
                                    toaster: 'b-toaster-top-center',
                                    solid: true
                                });
                            });
                        }
                        if (data.code == 422) {
                            this.$bvToast.toast(`error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    });
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="home-btn d-none d-sm-block">
            <a href="/">
                <i class="mdi mdi-home-variant h2 text-white"></i>
            </a>
        </div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4">
                                        <div>
                                            <div class="text-center">
                                                <!--                        <div>-->
                                                <!--                          <a href="/" class="logo">-->
                                                <!--                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />-->
                                                <!--                          </a>-->
                                                <!--                        </div>-->

                                                <h4 class="font-size-18 mt-4">パスワードの再設定</h4>
                                                <p class="text-muted">新しいパスワードを入力してください</p>
                                            </div>

                                            <div class="p-2 mt-5">
                                                <form class="form-horizontal" @submit.prevent="tryToLogIn">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="userpassword1"
                                                            >新しいパスワード
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            v-model="password"
                                                            type="password"
                                                            class="form-control"
                                                            id="userpassword1"
                                                            placeholder="*****************"
                                                            :class="{
                                                                'is-invalid': submitted && $v.password.$error
                                                            }"
                                                        />
                                                        <div v-if="submitted && $v.password.$error && !id" class="invalid-feedback">
                                                            <span v-if="!$v.password.required">新しいパスワード を入力してください。</span>
                                                            <span v-if="!$v.password.validate"
                                                                >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                            >
                                                        </div>
                                                    </div>

                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="userpassword">新しいパスワード <span class="text-danger">*</span> （確認）</label>
                                                        <input
                                                            v-model="password_confirm"
                                                            type="password"
                                                            class="form-control"
                                                            id="userpassword"
                                                            placeholder="*****************"
                                                            :class="{
                                                                'is-invalid': submitted && $v.password_confirm.$error
                                                            }"
                                                        />
                                                        <div v-if="submitted && $v.password_confirm.$error && !id" class="invalid-feedback">
                                                            <span v-if="!$v.password_confirm.required"
                                                                >新しいパスワード（確認）を入力してください。
                                                            </span>
                                                            <span v-if="!$v.password_confirm.validate"
                                                                >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                            >
                                                            <span v-else-if="!$v.password_confirm.sameAsPassword"
                                                                >新しいパスワードと新しいパスワード（確認）が一致しません。
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">送信</button>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        パスワードを覚えましたか？
                                                        <router-link tag="a" to="/login" class="text-danger">
                                                            <i class="mdi mdi-lock mr-1"></i> ログイン
                                                        </router-link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--          <div class="col-lg-8">-->
                    <!--            <div class="authentication-bg">-->
                    <!--              <div class="bg-overlay"></div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
